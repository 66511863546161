import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mainStore, pixStore, syngooMensagemConfiguracaoStore, syngooConfiguracaoStore, financeiroParcelaStore, asaasStore } from '@/store';
import _ from 'lodash';
import EnviarWhatsButton from '@/components/EnviarWhatsButton.vue';
let PixIntegradorPagamentoDialog = class PixIntegradorPagamentoDialog extends Vue {
    constructor() {
        super(...arguments);
        // 1 - Drive cobrando franqueado
        // 2 - Franqueado pagando o Drive
        // 3 - Franqueado cobrando o aluno
        // 4 - Aluno pagando o franqueado
        // 5 - Franqueado pagando o mkt digital
        // 6 - Franqueado pagando o fundo KNN
        // 7 - Franqueado pagando o fundo Phenom
        this.dialog = false;
        this.syngooMensagemId = null;
        this.loading = false;
        this.loadingConsultaStatus = false;
        this.pixDados = {};
        this.valorAtualizado = null;
        this.novoValorAtualizado = null;
        this.permiteEditarValor = false;
        this.editValorAtualizadoMode = false;
        this.mode = 1;
        this.whatsJaEnviado = false;
        this.syngooConfiguracaoStatus = null;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get disableEnviarWhats() {
        const unidadeEnvioFatura = _.find(this.syngooMensagemConfiguracaoItems, 
        // @ts-ignore
        item => item.syngoo_mensagem_name == 'Envio manual de fatura');
        // @ts-ignore
        return (!unidadeEnvioFatura.ativo_manual || this.possuiErro || this.whatsJaEnviado);
    }
    get syngooMensagemConfiguracaoItems() {
        return syngooMensagemConfiguracaoStore.syngooMensagemConfiguracao;
    }
    get possuiErro() {
        return !!this.pixDados.error;
    }
    async consultaPagamento() {
        this.financeiroParcela = await financeiroParcelaStore.getFinanceiroParcela(this.financeiroParcela.id);
        this.$forceUpdate();
        if (this.financeiroParcela.status_id ==
            // @ts-ignore
            this.getFinanceiroParcelaStatusIdPago()) {
            this.dialog = false;
            this.$emit('on-confirm');
        }
    }
    async copiarLink() {
        const loadingNotification = {
            content: 'Copiando código',
            showProgress: true
        };
        mainStore.addNotification(loadingNotification);
        if (!!navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(this.pixDados.pix_copia_cola);
        }
        else {
            const textArea = document.createElement('textarea');
            textArea.value = this.pixDados.pix_copia_cola;
            textArea.style.position = 'fixed';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            await new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
        mainStore.removeNotification(loadingNotification);
        mainStore.addNotification({
            content: 'Código copiado com sucesso',
            color: 'success'
        });
    }
    async salvaEdicaoValor() {
        this.$swal({
            title: 'Confirma a atualização da cobrança PIX?',
            html: 'Após a confirmação será gerado um novo QR Code e código para pagamento. <br/>Cobranças no valor menor que o valor do título irão constar como baixa parcial.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                this.valorAtualizado = this.novoValorAtualizado;
                this.editValorAtualizadoMode = false;
                this.pixDados = await pixStore.pixCobrancaCriar({
                    financeiro_parcela_id: this.financeiroParcela.id,
                    valor_integral: this.valorAtualizado
                });
                this.mode = 1;
                this.loading = false;
            }
        });
    }
    async cancelarEdicaoValor() {
        this.novoValorAtualizado = this.valorAtualizado;
        this.mode = 1;
        this.editValorAtualizadoMode = false;
    }
    async habilitaEdicaoValor() {
        this.mode = 2;
        this.novoValorAtualizado = this.valorAtualizado;
        this.editValorAtualizadoMode = true;
    }
    async editValorAtualizado() {
        this.novoValorAtualizado = this.valorAtualizado;
        this.editValorAtualizadoMode = true;
    }
    async enviarWhats() {
        this.$swal({
            title: 'Confirma o envio da cobrança no WhatsApp?',
            html: 'A mensagem será enviada para o celular do responsável financeiro<br/>A cobrança será enviada no valor integral',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // @ts-ignore
                await syngooMensagemConfiguracaoStore.SyngooEnviaWhats({
                    id: this.financeiroParcela.id,
                    syngoo_mensagem_id: this.financeiroParcela.syngoo_mensagem_id
                });
                this.whatsJaEnviado = true;
            }
        });
    }
    async show(financeiroParcela) {
        this.loading = true;
        this.syngooConfiguracaoStatus = await syngooConfiguracaoStore.getSyngooConfiguracaoStatus();
        const syngooMensagemConfiguracaoEnvioManual = await syngooMensagemConfiguracaoStore.getSyngooMensagemConfiguracaoEnvioManualFatura();
        this.syngooMensagemId =
            syngooMensagemConfiguracaoEnvioManual.syngoo_mensagem_id;
        this.pixDados = {};
        // 1 - Drive cobrando franqueado
        // 2 - Franqueado pagando o Drive
        // 3 - Franqueado cobrando o aluno
        // 4 - Aluno pagando o franqueado
        // 5 - Franqueado pagando o mkt digital
        // 6 - Franqueado pagando o fundo KNN
        // 7 - Franqueado pagando o fundo Phenom
        this.permiteEditarValor = false;
        this.dialog = true;
        this.financeiroParcela = financeiroParcela;
        this.valorAtualizado = financeiroParcela.valor_atualizado;
        const tem_integracao_aprovada_asaas = await asaasStore.companyParcelaTemIntegracaoAsaas(financeiroParcela.id);
        if (!tem_integracao_aprovada_asaas) {
            this.pixDados = await pixStore.pixCobrancaCriar({
                financeiro_parcela_id: this.financeiroParcela.id,
                valor_integral: this.valorAtualizado
            });
        }
        else {
            if (this.propTipoCobranca === 1) {
                const responseDate = await asaasStore.asaasCobrancaExecutePIXDriveFranqueado(financeiroParcela.id);
                if (!responseDate) {
                    this.dialog = false;
                }
                this.pixDados.pix_imagem_base64 = `data:image/jpeg;base64,${responseDate.encodedImage}`;
                this.pixDados.pix_copia_cola = responseDate.payload;
            }
            else if (this.propTipoCobranca === 2) {
                const responseDate = await asaasStore.asaasCobrancaExecutePIXFranqueadoDrive(financeiroParcela.id);
                if (!responseDate) {
                    this.dialog = false;
                }
                this.pixDados.pix_imagem_base64 = `data:image/jpeg;base64,${responseDate.encodedImage}`;
                this.pixDados.pix_copia_cola = responseDate.payload;
            }
            else {
                const responseDate = await asaasStore.asaasCobrancaExecutePIX(financeiroParcela.id);
                if (!responseDate) {
                    this.dialog = false;
                }
                this.pixDados.pix_imagem_base64 = `data:image/jpeg;base64,${responseDate.encodedImage}`;
                this.pixDados.pix_copia_cola = responseDate.payload;
            }
        }
        if ((this.propTipoCobranca == 1 || this.propTipoCobranca == 3) &&
            !this.possuiErro) {
            this.permiteEditarValor = true;
        }
        this.loading = false;
    }
    async close() {
        this.dialog = false;
        this.pixDados = {};
    }
};
__decorate([
    Prop({ default: false })
], PixIntegradorPagamentoDialog.prototype, "propRounded", void 0);
__decorate([
    Prop({ default: false })
], PixIntegradorPagamentoDialog.prototype, "propLarge", void 0);
__decorate([
    Prop({ default: 'primary' })
], PixIntegradorPagamentoDialog.prototype, "propClassAtualizar", void 0);
__decorate([
    Prop({ default: '' })
], PixIntegradorPagamentoDialog.prototype, "propStyle", void 0);
__decorate([
    Prop({ default: 'primary' })
], PixIntegradorPagamentoDialog.prototype, "propClassCopiar", void 0);
__decorate([
    Prop({ type: Number, default: 1 })
], PixIntegradorPagamentoDialog.prototype, "propTipoCobranca", void 0);
PixIntegradorPagamentoDialog = __decorate([
    Component({
        components: {
            EnviarWhatsButton
        }
    })
], PixIntegradorPagamentoDialog);
export default PixIntegradorPagamentoDialog;
