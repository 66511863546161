import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import { contratoStore, rotaStore, rotaPontoParadaStore, turmaStore, turmaAtividadeStore, } from '@/utils/store-accessor';
import { mainStore } from '@/store';
let ContratoChipComponent = class ContratoChipComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.contratoDados = null;
        this.rota = null;
        this.rotaPontoParada = null;
        this.turmaDados = {};
        this.turmaAtividadeDados = null;
        this.timer = 0;
        this.rotaHora = null;
        // public proximaAula = null;
        this.proximaAulaData = null;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get proximaAula() {
        return this.proximaAulaData;
    }
    formatCronograma(cronograma) {
        if (!cronograma || cronograma.length === 0) {
            return 'Nenhum horário disponível';
        }
        try {
            return cronograma
                .map((item) => `${item.dia_semana}: ${item.horario}`)
                .join(', ');
        }
        catch (e) {
            return 'Erro ao exibir cronograma';
        }
    }
    async mostrarDetalhe(contrato) {
        if (this.contratoDados && this.contratoDados.id == contrato.id) {
            return;
        }
        this.loading = true;
        this.contratoDados = null;
        this.contratoDados = await contratoStore.getContrato(contrato.id);
        this.turmaDados = await turmaStore.getTurma(this.contratoDados.turma_id);
        this.rotaPontoParada = await rotaPontoParadaStore.getRotaPontosParadaByAlunoId(contrato.aluno_id);
        if (this.rotaPontoParada && this.rotaPontoParada.length > 0) {
            const pontoParada = this.rotaPontoParada.find((ponto) => ponto.trajeto_ida === true);
            this.rotaHora = pontoParada?.hora;
            if (pontoParada) {
                const rotaId = pontoParada.rota_id;
                this.rota = await rotaStore.getRota(rotaId);
            }
        }
        this.proximaAulaData = await turmaAtividadeStore.getProximaAulaByTumaId(this.contratoDados.turma_id);
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Array })
], ContratoChipComponent.prototype, "propContratoItem", void 0);
ContratoChipComponent = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
        },
    })
], ContratoChipComponent);
export default ContratoChipComponent;
