var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[(_vm.propEntityFields.length > 0)?_c('div',[_c('v-radio-group',{attrs:{"row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Procurar por cupom ou agendamento:")])]},proxy:true}],null,false,776898737),model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},_vm._l((_vm.propEntityFields),function(item){return _c('v-radio',{key:item.label,attrs:{"label":("" + (item.label)),"value":item}})}),1)],1):_vm._e(),(_vm.propDisableValidator)?_c('div',[_c('v-autocomplete',{class:_vm.propCustomClass,style:(_vm.propEntityFields.length > 0 ? 'padding-top: 0;' : ''),attrs:{"type":"search","name":"new_entidade","autocomplete":"new_entidade","cache-items":"","hide-no-data":"","hide-details":"","loading":_vm.loadingEntidade,"items":_vm.entidadesOptions,"search-input":_vm.searchEntidade,"label":_vm.propTitle,"item-text":"nome","item-value":"id","return-object":"","disabled":_vm.propDisabled,"clearable":"","single-line":_vm.propDense,"dense":_vm.propDense,"outlined":_vm.propOutlined,"rounded":_vm.propRounded,"readonly":_vm.propReadonly,"filter":_vm.customFilter},on:{"update:searchInput":function($event){_vm.searchEntidade=$event},"update:search-input":function($event){_vm.searchEntidade=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" "+_vm._s(item.departamento_nome ? ' - ' + item.departamento_nome : '')+" - "+_vm._s((item.tipo === 'Prospex' && _vm.userProfile.company.escola_id == 2) ? 'Check-in' : item.tipo)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return (item.nome)?[_c('span',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.nome)+" "+_vm._s(item.departamento_nome ? ' - ' + item.departamento_nome : '')+" - "+_vm._s((item.tipo === 'Prospex' && _vm.userProfile.company.escola_id == 2) ? 'Check-in' : item.tipo)+" "),_c('div',{staticStyle:{"font-size":"0.7em","margin-top":"-0.3rem"}},[(item.idade > 0)?_c('span',[_vm._v(" "+_vm._s(item.idade)+" anos ")]):_vm._e(),(item.parceria_nome)?_c('span',[_vm._v(" Parceria "+_vm._s(item.parceria_nome)+" ")]):_vm._e()])])]:undefined}}],null,true),model:{value:(_vm.selectedEntidade),callback:function ($$v) {_vm.selectedEntidade=$$v},expression:"selectedEntidade"}})],1):_c('div',[_c('validation-observer',{ref:"observer"},[_c('validation-provider',{attrs:{"debounce":100,"name":_vm.propTitle,"vid":"selectedEntidade","rules.disable":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{class:_vm.propCustomClass,style:(_vm.propEntityFields.length > 0 ? 'padding-top: 0;' : ''),attrs:{"type":"search","name":"new_entidade","autocomplete":"new_entidade","cache-items":"","hide-no-data":"","loading":_vm.loadingEntidade,"items":_vm.entidadesOptions,"search-input":_vm.searchEntidade,"label":_vm.propTitle,"item-text":"nome","item-value":"id","return-object":"","error-messages":_vm.validatorErrors.length > 0 ? _vm.validatorErrors : errors,"disabled":_vm.propDisabled,"clearable":"","single-line":_vm.propDense,"dense":_vm.propDense,"outlined":_vm.propOutlined,"rounded":_vm.propRounded,"readonly":_vm.propReadonly,"filter":_vm.customFilter},on:{"update:searchInput":function($event){_vm.searchEntidade=$event},"update:search-input":function($event){_vm.searchEntidade=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return (item.nome)?[_vm._v(" "+_vm._s(item.nome)+" - "+_vm._s((item.tipo === 'Prospex' && _vm.userProfile.company.escola_id == 2) ? 'Check-in' : item.tipo)+" ")]:undefined}},{key:"item",fn:function(ref){
var item = ref.item;
return (item.nome)?[_c('span',[_vm._v(" "+_vm._s(item.nome)+" "),(item.idade > 0)?_c('span',{staticStyle:{"font-size":"0.7em"}},[_vm._v("( "+_vm._s(item.idade)+" anos ) - ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s((item.tipo === 'Prospex' && _vm.userProfile.company.escola_id == 2) ? 'Check-in' : item.tipo)+" ")]),_c('div',{staticStyle:{"font-size":"0.7em","margin-top":"-0.3rem"}},[(item.parceria_nome)?_c('span',[_vm._v(" Parceria: "+_vm._s(item.parceria_nome)+" ")]):_vm._e(),(item.telefone)?_c('span',[_vm._v(" - Contato: "+_vm._s(item.telefone)+" ")]):_vm._e()])])]:undefined}}],null,true),model:{value:(_vm.selectedEntidade),callback:function ($$v) {_vm.selectedEntidade=$$v},expression:"selectedEntidade"}})]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }